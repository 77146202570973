<template>
    <div>ss</div>
</template>

<script>

    import { mapGetters, mapActions } from "vuex";

    export default {
        computed: {
            ...mapGetters([
                "getUserData",
            ])
        },
        methods: {
            ...mapActions(["getToken"])
        },
        async mounted() {
            
            if (this.$route.params.id) {

                let data = {
                    url: this.$w_api.CHANGE_VERSION,
                    body: {
                        user_id: this.$route.params.id
                    }
                }

                let res = await this.getToken(data)

                let meta1 = document.createElement('meta')
                meta1.httpEquiv = "cache-control"
                meta1.content = "no-cache"
                document.head.appendChild(meta1)

                let meta2 = document.createElement('meta')
                meta2.httpEquiv = "expires"
                meta2.content = "0"
                document.head.appendChild(meta2)

                let meta3 = document.createElement('meta')
                meta3.httpEquiv = "pragma"
                meta3.content = "no-cache"
                document.head.appendChild(meta3)

                setTimeout(() => {
                    window.location.href = process.env.VUE_APP_FRONTEND + '/auth-token/' + this.$route.params.id
                }, 100)

            }

        }
    }

</script>